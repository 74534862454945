.listClass {
    background-color: #f1f1f1;
    width: 50px;
    margin: 25px;
    float: right;
    text-align: center;
    line-height: 50px;
    font-size: 1.5vh;
  }
  .listClass > img:hover{
    /* background-color: green; */
    opacity: 50%;
  }
  @media screen and (max-width:800px) { 
    .listClass > img:hover{
      /* background-color: green; */
     opacity: 100%;
    } 
  }
.nobull {
    list-style-type: none;
    display:flex;
    flex-wrap: wrap;
   }
