.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 10px;
  width: 80%;
  margin: auto;
  
}

.login__textBox {
  padding: 10px;
  font-size: 15px;
  margin-bottom: 10px;

}

.login__btn {
  padding: 10px;
  font-size: 12 px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.login__google {
  background-color: #4285f4;
}

.login div {
  margin-top: 7px;
}
